<template lang="">
    <Card>
        <template #header>
            Görüşler 
        </template>
        <div class="form-group">
            <label for="">Operasyon Görüşleri</label>
            <textarea name="" id="" rows="4" class="form-control"></textarea>
        </div>
        <div class="d-flex justify-content-end">
            <button class="btn btn-success">Kaydet</button>
        </div>
    </Card>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>